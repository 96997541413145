<template>
  <v-app>
    <div class="text-center ma-5" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate/>
      <br/>
      お客様の情報を確認中です。<br/>
      処理が完了するまでお待ちください。
    </div>

    <v-alert
      v-model="alert"
      close-text="Close Alert"
      color="orange accent-4"
      dark
      dismissible
      class="ma-5"
    >
      {{error_message}}
    </v-alert>
    <v-btn
      v-if="is_go_priscription"
      class="ma-5 text-center "
      color="primary"
      v-on:click="go_priscription_list()"
    >
        処方箋を表示
      </v-btn>
    <div class="text-center ma-5" v-if="loading === false">
      <b>診察券をご用意ください。</b>
      <br/>
      診察券が収まるように位置を合わせて<br/>「カメラボタン」を押して撮影してください。
      <br/>
      <video ref="video" id="video"  autoplay playsinline muted></video>
      <div>
        <v-btn
          class="ma-5 text-center "
          fab
          x-large
          dark
          id="snap"
          color="indigo"
          v-on:click="capture()"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>
      <canvas ref="canvas" id="canvas" ></canvas>
    </div>
  </v-app>
</template>

<script>

import { Firebase } from "../config/firebase"

export default {
  name: 'PatientRegster',

  data(){
    return {
      requestUrl: 'http://localhost:5001/clinicdx/asia-northeast1/annotateImage', // HTTPリクエストのエンドポイント

      loading: true,
      showcamera: false,
      is_go_priscription: false,
      regist: false,
      user_name: "",
      welcome: null,
      alert: false,
      error_message: "",
      firestorage: null,
      storageRef: null,
      image: null,
      image_url: "",
      video: {},
      canvas: {},
      capture_image: null,
      patient: null,
      patient_id: ""
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    user_id() {
      return this.$store.getters.user.uid
    },
    clinic(){
      if(this.id === ""){
        return undefined
      }else{
        return this.$store.state.clinicModule.data[this.id]
      }
    },
    user() {
      return this.$store.state.userModule.data[this.user_id]
    },
  },

  created: async function () {
    try {
      await this.fetchUsers()
    } catch(e) {
      this.error = true
    }
    try {
      this.fetchClinics()
    } catch (e) {
      this.error = true
    }
  },
  methods: {
    fetchUsers: async function () {
      await this.$store.dispatch('userModule/fetchById', this.$store.getters.user.uid)
        this.loading = false
    },
    fetchClinics: async function () {
      await this.$store.commit('clinicModule/RESET_VUEX_EASY_FIRESTORE_STATE')
      try {
        await this.$store.dispatch('clinicModule/fetchById', this.id)
        const clinic = this.$store.state.clinicModule.data[this.id]
        if(clinic.is_card_scan == false){
          this.loading = true
          await Firebase
            .firestore()
            .collection("Patients")
            .where("clinic_id", "==",     this.id)
            .where("phoneNumber", "==", this.user.phone)
            .get()
            .then((querySnapshot) => {
              if(!querySnapshot.empty){
                querySnapshot.forEach((doc) => {
                  const patient = doc.data()
                  const clinics = this.user.clinics
                  let is_registed = false
                  for(let i=0; i< clinics.length; i++){
                    clinics[i].clinic_id == this.id
                    is_registed =
                    console.log("clinicsは登録済み")
                    break
                  }
                  if(is_registed){
                    this.$router.push({ name: "priscription_list", params: { id: patient.clinic_id }, query: {patientNumber: patient.patientNumber, patient_id: patient.id} })
                  }else{
                    const clinic = {
                      clinic_id: patient.clinic_id,
                      clinic_name: patient.clinic_name,
                      patient_id: patient.id,
                      patient_name: patient.name,
                      patientNumber: patient.patientNumber
                    }
                    this.user.clinics.push(clinic)
                    this.$store.dispatch('userModule/patch',this.user).then(() => {
                    this.$router.push({ name: "priscription_list", params: { id: patient.clinic_id }, query: {patientNumber: patient.patientNumber, patient_id: patient.id} })
                    })
                  }
                });
              }else{
                this.loading = false
                console.log("patinen not registed")

                this.$router.push({ path: '/patient_form_regster/' + this.id })
              }
            })
            .catch((error) => {
              throw new Error(
                Date().toLocaleString("ja") +
                  " [erro code: 121] Patients: get error" + error
              );
            });
        }else{

            this.showcamera = true
            this.canvasSizeX = 300; //canvasの幅
            this.canvasSizeY = (this.canvasSizeX*this.video.videoHeight)/this.video.videoWidth; //canvasの高さ
            this.canvas = this.$refs.canvas

            this.video = await this.$refs.video
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
              navigator.mediaDevices.getUserMedia(
                {
                  audio: false,
                  video: {
                    aspectRatio: 1.777777,
                    height: {min: 640, max: 1920},
                    width: {min: 480, max: 1080},
                    facingMode: 'environment'
                  }
                }
              ).then(stream => {
                this.video.srcObject = stream
                const videoElement = document.createElement("video")
                videoElement.autoplay = true;
                document.body.append(videoElement);
                videoElement.addEventListener("resize", () => {
                  videoElement.width = videoElement.videoWidth;
                  videoElement.height = videoElement.videoHeight;
                });
                this.video.play()
              })
            }
        }
      } catch(e) {
        this.error = true
        console.error("error:",e)
        //this.$router.push({ name: "error", params: { message:  "表示できません。" } })
      }
      this.image_url =  "/images/cards/" + this.id + ".png"
    },
    capture: async function () {
      this.loading = true
      this.canvas = this.$refs.canvas
      var w = this.video.offsetWidth;
      var h = this.video.offsetHeight;
      this.canvas.setAttribute('width', w);
      this.canvas.setAttribute('height', h);
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, w, h)
      this.capture_image = this.canvas.toDataURL('image/png').replace(/^.*,/, '')
      const params = {
        "image": {"content": this.capture_image},
        "features": [{"type": "TEXT_DETECTION"}],
        "imageContext": { "languageHints": ["ja"]}
      }
      await this.annotateImage(params)
    },
    async annotateImage(params) {
      const functions = Firebase.app().functions('asia-northeast1');
      const httpEvent = await functions.httpsCallable("annotateImage");
      httpEvent(params).then((res) => {
        const testArray = res.data[0].fullTextAnnotation.text.split('\n');
        console.log("testArray", testArray)
        let patient_number = ""
        let patient_name = ""
        let patient_kana_name = ""
        let is_phone_number = false
        if(this.id == "ry2EHMZ5OCN20M6dld1e"){
          for (const text of testArray) {
            console.log("--", text)
            //電話番号を確認
            if(text.includes(this.clinic.clinic_ticket.phone_number)){
              is_phone_number = true
            }

            //お名前取得
            let temp_text = text
            temp_text = temp_text.replace(/\u3000/g, '');
            temp_text = temp_text.replace(/ /g, '');
            temp_text = temp_text.replace(this.clinic.clinic_ticket.jd,"");
            temp_text = temp_text.replace(/\u3000/g, '');
            temp_text = temp_text.replace(/ /g, '');
            //患者番号の長さでチェック10文字だった場合
            if(temp_text.length == this.clinic.clinic_ticket.patient_number.length){
              patient_number = temp_text
            }else{
              if(temp_text.includes(this.clinic.clinic_ticket.name)){
                patient_name = temp_text.replace(this.clinic.clinic_ticket.name,"");
              }
            }
          }
        }else if(this.id == "xOm5Jo5b30c1c4DNtVg2"){
           patient_number = testArray[1].replace(/ /g, '');
           patient_kana_name = testArray[2].replace(/ /g, '');
           patient_name = testArray[3].replace(/ /g, '');
          //電話番号を確認
          if(res.data[0].fullTextAnnotation.text.includes(this.clinic.clinic_ticket.phone_number)){
            is_phone_number = true
          }
        }else if(this.id == "g4cnpSzuXF3QHF9UNfNn"){
           patient_number = testArray[1].replace(/ /g, ''.replace(/No./g, ''))
           if(testArray[2].includes('co')|| testArray[2].includes('.')|| testArray[2].includes('No.')|| testArray[2]=='O'|| testArray[2]=='0'){
            patient_kana_name = testArray[3].replace(/ /g, '').replace(/M/g, '').replace(/様/g, '')
            patient_name = testArray[3].replace(/ /g, '').replace(/M/g, '').replace(/様/g, '')
           }
           else{
            patient_kana_name = testArray[2].replace(/ /g, '').replace(/M/g, '').replace(/様/g, '')
            patient_name = testArray[2].replace(/ /g, '').replace(/M/g, '').replace(/様/g, '')
           }
          //電話番号を確認
          if(res.data[0].fullTextAnnotation.text.includes(this.clinic.clinic_ticket.phone_number)){
            is_phone_number = true
          }
        }
        if(is_phone_number == false){
          this.error_message = "読み取りが失敗しました。だだしい診察券で再度撮影をおねがいします。\n" + res.data[0].fullTextAnnotation.text
          this.loading = false
          this.alert = true
          return
        }

        if(patient_number == ""){
          this.error_message = "患者様番号の読み取りが失敗しました。再度撮影をおねがいします。\n" + res.data[0].fullTextAnnotation.text
          this.loading = false
          this.alert = true
          return
        }

        if(patient_name == ""){
          this.error_message = "お名前の読み取りが失敗しました。再度撮影をおねがいします。\n" + res.data[0].fullTextAnnotation.text
          this.loading = false
          this.alert = true
          return
        }

        if(patient_name == "M"){
          this.error_message = "お名前の読み取りが失敗しました。診察券全体が枠にピッタリと入るように再度撮影をおねがいします。\n" + res.data[0].fullTextAnnotation.text
          this.loading = false
          this.alert = true
          return
        }

        const patient = {
          is_phone_number: is_phone_number,
          clinic_id: this.clinic.id,
          clinic_name: this.clinic.name,
          user_id: this.user.id,
          email: this.user.email,
          kana: patient_kana_name,
          name: patient_name,
          patientNumber: patient_number,
          phoneNumber: this.user.phone,
          status: true,
          is_self_regist: true
        }
        this.addPatientCode(patient)
      })
      .catch((error) => {
        this.alert = true
        console.log("annotateImage error:",error); // { message: error }
        if(error.message.includes('Unauthenticated')){
          this.error_message = "ログインからやり直してください。"
        }
      });
    },


    async addPatientCode(patient) {
      let patient_id = ""
      this.patient = patient
      this.alert = false
      patient_id = await Firebase
        .firestore()
        .collection("Patients")
        .where("clinic_id", "==",     this.id)
        .where("patientNumber", "==", patient.patientNumber)
        .where("user_id", "==",       this.user_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.patient_id = doc.id
            this.error_message = "すでに登録済みです。"
            this.loading = false
            this.alert = true
            this.is_go_priscription = true
          });
          return patient_id
        })
        .catch((error) => {
          throw new Error(
            Date().toLocaleString("ja") +
              " [erro code: 121] Patients: get error" + error
          );
        });
      if(this.patient_id != ""){
        console.log("this.patient_id ",this.patient_id)
        return
      }

      // Create patient document
      const patientRef = Firebase.firestore().collection('Patients')
      const user_clinic = await patientRef.add(patient).then((doc) => {
        this.patient.id = doc.id
        const user_clinic =
        {
          clinic_id: this.clinic.id,
          clinic_name: this.clinic.name,
          patient_id: doc.id,
          patientNumber: patient.patientNumber,
          patient_name: patient.name,
        }
        return user_clinic
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
      // Add clinic and patient under User clinics array
      await Firebase.firestore().collection("Users").doc(this.user_id).update({
        clinics: Firebase.firestore.FieldValue.arrayUnion(user_clinic)
      }).then(() => {
        console.log("User document update");
        this.patient_id = this.patient.id
        this.go_priscription_list()
        return
      })
      return
    },
    go_priscription_list(){
      this.$router.push({ name: "priscription_list", params: { id: this.id }, query: {patientNumber: this.patient.patientNumber, patient_id: this.patient_id} })
    }
  }
};
</script>

<style>
#canvas {
  display: none;
}
#video {
  border: 1px solid #333;
  background: #f2f2f2;
  width: 70%;
}
#image{
  border: 1px solid #333;
  background: #f2f2f2;
}
</style>
